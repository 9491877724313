<template>
    <div>
        <div id="mainLoading" v-show="this.loading == true" class="darkBackground" style="text-align: center;">
            <div class="text-center" style="margin-top:25vh">
                <img width="250px"  :src="require('/public/BirdhuntWhiteLogoCropped1.png')">
                <!-- <v-progress-linear indeterminate color="1d9bf0" style="width: 150px; margin-left: auto; margin-right:auto; margin-top: 1.5rem;" height=3 background-opacity="0"></v-progress-linear> -->
            </div>
        </div>
        <!-- Overlay which dims the entire screen -->
            <v-overlay
                opacity="0.66"
                color="#000000"
                :value="dialog"
            ></v-overlay>

            <!-- this is the dialog box itself the customDialog class gives you the ability to style it using custom css ensure the stlye tag isn't scoped -->
            <v-dialog
                v-model="dialog"
                persistent
                content-class="customDialog noScroll"
                fullscreen
                transition="dialog-bottom-transition"
            >
                <!-- no shadow and no rounded corners on the card -->
                <v-card class="rounded-0" elavation="0" dark color="#0D1117">
                    <!-- white "flat look" vuetify tool bar -->
                    <v-toolbar class="elevation-0" dark color="#0D1117">
                        <!-- this is used as a spacer for the title -->
                        <div class="col-1 hidden-mobile padding-mobile" />

                        <!-- title of dialog box -->
                        <v-toolbar-title
                            ><strong style="color: #1d9bf0;"
                                >Find Tweets by Location</strong
                            ></v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <!-- close button and icon -->
                            <v-btn icon @click="close()">
                                <v-icon style="color: #1d9bf0;">mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>

                        <div class="col-1 hidden-mobile padding-mobile" />
                    </v-toolbar>

                    <!-- the coloured divider between the header and the content -->
                    <v-divider class="mt-0 mb-3" style="color: #1d9bf0;"></v-divider>

                    <div class="row m-0 p-2">
                        <!-- spacer for the dialog content -->
                        <div class="col-1 hidden-mobile padding-mobile" />

                        <div class="col-12 col-md-10 text-center padding-mobile">
                            <p style="text-align: left; color: rgb(167, 181, 196)"><b>BirdHunt will show you all tweets within the chosen geographic location</b> that you are permitted to see (depending on a user’s settings their tweets may not be accessible).</p>

                            <v-divider class="my-6"></v-divider>

                            <p class="my-6" style="text-align: left; color: rgb(167, 181, 196)"><span style="color: rgb(204, 218, 231);">Final Step:</span><br><b>Choose the radius</b> in which you want your tweets to be. For example, within 10km of your chosen location.</p>
                            <v-slider color="#1d9bf0" style="margin-top: 85px;" v-model="slider" label="Radius:" step="0.1" :thumb-size="50" min="0" max="25" thumb-label="always">
                                <template v-slot:thumb-label="{ value }">
                                    {{ value }}{{ unit }}
                                </template>
                            </v-slider>

                            <p class="mb-6 mt-0" @click="resetOptions" style="text-align: right; text-decoration: underline; cursor: pointer; color: #1d9bf0;"><span v-if="!showOptions">Show</span><span v-else>Close</span> Advanced Options</p>

                            <div v-if="showOptions">
                            <p class="my-6" style="text-align: left; color: rgb(204, 218, 231);">👩‍🔬 <b>Advanced Options</b> 👩‍🔬</p>
                            <p class="my-6" style="text-align: left; color: rgb(167, 181, 196)">🔎 <b> Add a search Query</b> to search your geo-filtered tweets. For example, 'Super Bowl Sunday'.</p>
                            <v-text-field v-model="searchQuery" color="#1d9bf0" placeholder="Example: Super Bowl Sunday" label="Search Query (Optional)"></v-text-field>

                             <p class="my-6" style="text-align: left; color: rgb(167, 181, 196)">📷 <b>Search tweets by media type</b> - Find tweets that contain only images and videos, just images or just videos.</p>
                                <v-radio-group v-model="filter">
                                <template v-slot:label>
                                    <div><b>Only find tweets that contain (Optional):</b></div>
                                </template>
                                <v-radio color="#1d9bf0" value=''>
                                    <template v-slot:label>
                                    <div>All Tweets 🐦</div>
                                    </template>
                                </v-radio>
                                <v-radio color="#1d9bf0" value="media">
                                    <template v-slot:label>
                                    <div>Just Images and Video 📷📹</div>
                                    </template>
                                </v-radio>
                                <v-radio color="#1d9bf0" value="images">
                                    <template v-slot:label>
                                    <div>Just Images (including Instagram) 📷</div>
                                    </template>
                                </v-radio>
                                <v-radio color="#1d9bf0" value="native_video">
                                    <template v-slot:label>
                                    <div>Just Video 📹</div>
                                    </template>
                                </v-radio>
                                </v-radio-group>

                            <p class="my-6" style="text-align: left; color: rgb(167, 181, 196)">❓<b> Search question tweets</b> - Find tweets that contain questions.</p>
                            <v-checkbox color='#1d9bf0'
                            v-model="question"
                            label="Just Questions (Optional)"
                            ></v-checkbox>

                            <p class="my-6" style="text-align: left; color: rgb(167, 181, 196)">❤️ <b>Add a Minimum Like Count</b> to narrow down your geo-filtered tweets. For example, '100'.</p>
                            <v-slider color="#1d9bf0" style="margin-top: 85px;" v-model="minLikes" label="Minimum Likes:" step="1" :thumb-size="50" min="0" max="200" thumb-label="always">
                                <template v-slot:thumb-label="{ value }">
                                    {{ value }} ❤️
                                </template>
                            </v-slider>

                            <p class="my-6" style="text-align: left; color: rgb(167, 181, 196)">🔁 <b>Add a Minimum Retweet Count</b> to filter your geo-filtered tweets. For example, '50'.</p>
                            <v-slider color="#1d9bf0" style="margin-top: 85px;" v-model="minRetweets" label="Minimum Retweets:" step="1" :thumb-size="50" min="0" max="100" thumb-label="always">
                                <template v-slot:thumb-label="{ value }">
                                    {{ value }} 🔁
                                </template>
                            </v-slider>

                            <p class="my-6" style="text-align: left; color: rgb(167, 181, 196)">↩️ <b>Filter out replies</b> - Only show tweets that aren't replies.</p>
                            <v-radio-group v-model="replyFilter">
                                <template v-slot:label>
                                    <div><b>Reply Filters - Show tweets based on reply status (Optional):</b></div>
                                </template>
                                <v-radio color="#1d9bf0" value=''>
                                    <template v-slot:label>
                                    <div>All Tweets 🐦</div>
                                    </template>
                                </v-radio>
                                <v-radio color="#1d9bf0" value="only-replies">
                                    <template v-slot:label>
                                    <div>Only Replies ↩️❗</div>
                                    </template>
                                </v-radio>
                                <v-radio color="#1d9bf0" value="no-replies">
                                    <template v-slot:label>
                                    <div>No Replies ❌↩️</div>
                                    </template>
                                </v-radio>
                            </v-radio-group>

                            <p class="my-6" style="text-align: left; color: rgb(167, 181, 196)"><b>Select Language</b> - Only show tweets that are a certain language.</p>
                            <v-select
                                v-model="languageSelect"
                                :items="languages"
                                label="Select a Language"
                            ></v-select>
                            </div>
        

                            <div class="p-0 mt-6 m-0">
                                    <v-btn
                                        color="#1d9bf0"
                                        elevation="0"
                                        class="col-12 no-padding"
                                        :disabled="!slider"
                                        @click="goToTwitter()"
                                        style="text-transform: none; letter-spacing: normal; text-decoration: none;"
                                        ><span style="color: white;">Search For Tweets <v-icon style="color: white !important; font-size: 0.9rem;">mdi-twitter</v-icon></span></v-btn>
                                    <v-alert text dense class="col-12 my-3 text-left" v-if="showRadiusWarning" type="warning">Please choose a radius</v-alert>

                                    <!-- adding space to the bottom of the dialog box -->
                                    <div class="buttonSpacer"></div>
                                </div>

                        </div>
                    </div>
                </v-card>
            </v-dialog>
            <v-btn id="submissionBtn" color="#1d9bf0" elevation="0" class="no-padding" @click="findTweetsClicked()"><span style="color: white; text-decoration: none; letter-spacing: normal; text-transform: none;">Confirm Location <v-icon style="font-size: 0.9rem;">mdi-map-marker-check</v-icon> </span></v-btn>
            <v-alert dense id="alert" v-if="errorMessage" type="error"><span class="hidden-mobile">No Location Selected! Please click on the map to s</span><span class="visible-mobile">S</span>elect a location to search for tweets</v-alert>
        <div id="map">
            <mapbox
                access-token="pk.eyJ1IjoiYmlyZGh1bnQiLCJhIjoiY2t6YThnY2FrMDlncjJ3czZjNTZ5YzBpNSJ9.72hEYWjdoe8avgkItuQBug"
                :map-options="{
                    style: 'mapbox://styles/birdhunt/ckzf3vnph000414ny5b52cb18',
                    center: ['0', '7'],
                    zoom: 2,
                }"
                :attribution-control="{
                    show: true,
                    position:'bottom-right'
                }"
                :geocoder-control="{
                    show: true,
                    position: 'top-right'
                }"
                :fullscreen-control="{
                    show: true,
                    position: 'bottom-right'
                }"
                @map-init="initialiseMap"
                @map-load="loaded"
                @map-click="mapClicked"
                @map-dblclick="doubleClicked"
            />
        </div>

    </div>
</template>

<script>

import Mapbox from "mapbox-gl-vue";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'

export default {

    props: {
        mapStyle: String,
        disUnit: String,
    },
    
    name: 'mapBirdHunt',

    components:{
        Mapbox
    },

    data(){
        return {
            loading: true,
            marker: null,
            popup: null,
            dialog: false,
            dbl: false,
            clicked: false,
            errorMessage: false,
            slider: null,
            url: null,
            lat: null,
            lon: null,
            showOptions: false,
            searchQuery: '',
            filter: null,
            replyFilter: null,
            question: false,
            minLikes: null,
            minRetweets:null,
            unit: 'KM',
            disMult: 1,
            languageSelect: '',
            languages: [
                {text: 'Any Language', value: ''}, {text: 'English', value: 'en'}, {text: 'Arabic', value: 'ar'}, {text: 'Bengali', value: 'bn'}, {text: 'Czech', value: 'cs'}, {text: 'Danish', value: 'da'}, {text: 'German', value: 'de'}, {text: 'Greek', value: 'el'}, 
                {text: 'Spanish', value: 'es'}, {text: 'Persian', value: 'fa'}, {text: 'Finnish', value: 'fi'}, {text: 'Filipino', value: 'fil'},{text: 'French', value: 'fr'},{text: 'Hebrew', value:'he'}, {text: 'Hindi', value: 'hi'},
                {text: 'Hungarian', value: 'hu'}, {text: 'Indonesian', value: 'id'}, {text: 'Italian', value: 'it'}, {text: 'Japanese', value: 'ja'}, {text: 'Korean', value: 'ko'}, {text: 'Malay', value: 'msa'}, {text: 'Dutch', value: 'nl'},
                {text: 'Norwegian', value: 'no'}, {text: 'Polish', value: 'pl'}, {text: 'Portuguese', value: 'pt'}, {text: 'Romanian', value: 'ro'}, {text: 'Russian', value: 'ru'}, {text: 'Swedish', value: 'sv'}, {text: 'Thai', value: 'th'},
                {text: 'Turkish', value: 'tr'}, {text: 'Ukrainian', value: 'uk'}, {text: 'Urdu', value: 'ur'}, {text: 'Vietnamese', value: 'vi'}, {text: 'Chinese (Simplified)', value: 'zh-cn'}, {text: 'Chinese (Traditional)', value: 'zh-tw'},
                ],
        }
    },

    methods: {
        loaded: function(map) {
            this.loading = false;
            map.on('idle',function(){
                map.resize()
            })
        },
        initialiseMap: function(map) {
            this.map = map;
            const geocoder = new MapboxGeocoder({
                accessToken: "pk.eyJ1IjoiYmlyZGh1bnQiLCJhIjoiY2t6YThnY2FrMDlncjJ3czZjNTZ5YzBpNSJ9.72hEYWjdoe8avgkItuQBug",
            })

            map.addControl(
                geocoder
            );

            geocoder.on('result', (event) => {
                if(this.marker){
                    this.marker.remove();  
                }
                const el = document.createElement('div');
                el.className = 'marker';
                var coordinates = event.result.geometry.coordinates;
                this.marker = new mapboxgl.Marker(el);
                this.marker.setLngLat(coordinates).addTo(this.map);

                this.clicked = true;
                this.errorMessage = false;
            });
        },
        mapClicked: function(map, e){
            this.dbl = false;

            setTimeout(() => {
            if (!this.dbl) {
                if(this.marker){
                    this.marker.remove();  
                }
                if(this.popup){
                    this.popup.$destroy();
                    if(this.popup.$el.parentNode){
                        this.popup.$el.parentNode.removeChild(this.popup.$el);
                    }
                }
                if(this.popupSmall){
                    this.popupSmall.remove();
                }
                const el = document.createElement('div');
                el.className = 'marker';
                var coordinates = e.lngLat;
                this.marker = new mapboxgl.Marker(el);
                this.marker.setLngLat(coordinates).addTo(this.map);

                this.clicked = true;
                this.errorMessage = false;
            }
            }, 500)
        },
        doubleClicked: function(){
            this.dbl = true;
        },
        findTweetsClicked: function(){
            if(this.clicked){
                this.lat = this.marker._lngLat.lat;
                this.lon = this.marker._lngLat.lng;
                this.dialog = true;

            } else {
                this.errorMessage = true;
            }

        },
        goToTwitter(){
            this.url = 'https://twitter.com/search?q=' + this.searchQuery + ' geocode%3A' + this.lat + '%2C' + this.lon + '%2C' 
            if(this.disUnit == 'miles'){
                this.url = this.url + (Math.round((this.slider * 1.609344)* 100)/100) + 'km'
            } else {
                this.url = this.url + this.slider + 'km'
            }
            if(this.filter){
                this.url = this.url + ' ' + 'filter:' + this.filter;
            }
            if(this.replyFilter){
                if(this.replyFilter == 'no-replies'){
                    this.url = this.url + ' ' + '-filter:' + 'replies';
                } else {
                    this.url = this.url + ' ' + 'filter:' + 'replies';
                }
            }
            if(this.question){
                this.url = this.url + ' ' + '?';
            }
            if(this.minLikes){
                this.url = this.url + ' ' + 'min_faves:' + this.minLikes;
            }
            if(this.minRetweets){
                this.url = this.url + ' ' + 'min_retweets:' + this.minRetweets;
            }
            if(this.languageSelect){
                this.url = this.url + ' ' + 'lang:' + this.languageSelect;
            }
            this.url = this.url + '&src=typed_query&f=live'
            window.open(this.url); 
        },
        close(){
            this.dialog = false;
            this.slider = null;
            this.lat = null;
            this.lon = null;
            this.showOptions = false;
            this.searchQuery = '';
            this.filter = null;
            this.replyFilter = null;
            this.question = false;
            this.minLikes = null;
            this.minRetweets = null;
            this.languageSelect = '';
        },
        resetOptions(){
            this.searchQuery = '';
            this.showOptions = !this.showOptions;
            this.filter = null;
            this.replyFilter = null;
            this.question = false;
            this.minLikes = null;
            this.minRetweets = null;
            this.languageSelect = '';
        },
    },
    computed: {
        showRadiusWarning() {
            if(this.slider){
                return false;
            } else {
                if(this.filter || this.question || this.searchQuery || this.minLikes || this.minRetweets ||  this.replyFilter || this.languageSelect){
                    return true;
                } else {
                    return false;
                }
            }
        },
    }, 
    watch : {
        mapStyle(){
            if(this.mapStyle == 'satellite'){
                this.map.setStyle('mapbox://styles/birdhunt/ckzf3vnph000414ny5b52cb18');
            } else {
                this.map.setStyle('mapbox://styles/birdhunt/ckza8kmwp002r15pcg5x6pf0c');
            }
            this.$emit('styleChanged', true);
        },
        disUnit(){
            if(this.disUnit == 'kilometers'){
                this.unit = 'KM';
            } else {
                this.unit = 'mi.';
            }
            this.$emit('styleChanged', true);
        }
    },
}
</script>

<style>
#mainLoading { z-index: 4; position: absolute; width: 100%;  height: 100% !important; overflow-y: hidden !important;}
#map { z-index: 0 !important; position: absolute !important; width: 100% !important; height: 100% !important; border-width: unset !important; border-style: unset !important; border-color: unset !important; overflow-y: hidden !important;}

.background{
    background: #1d9bf0;
}

.darkBackground{
    background: #0D1117;
}

.marker {
  background-image: url('/public/BirdHuntPin.png');
  background-size: contain;
  width: 30px;
    height: 80px;
  cursor: pointer;
}
.customDialog.v-dialog--fullscreen {
    width: 41.66%;
    right: 0px;
    left: unset;
    border-radius: 0px;
}
.buttonSpacer {
    margin-top: 20vh;
}
#submissionBtn{z-index: 3 !important; position: absolute !important; width: 20% !important; margin-top: 10px; margin-left: 10px; max-width: 300px; min-width: 240px;}
#alert{z-index: 3 !important; position: absolute !important; margin-bottom: 30px; width: calc(100% - 580px); margin-top:10px; margin-right: calc(10px + 240px + 10px); margin-left: calc(310px + 10px); height: 36px;}

@media (min-width: 767px) and (max-width: 1140px) {
    #alert{z-index: 3 !important; position: absolute !important; margin-bottom: 30px; width: calc(100% - 550px); margin-top:10px; margin-right: calc(10px + 240px + 10px); margin-left: calc(280px + 10px); height: 36px;}
}

@media (max-width: 767px) {
        .visible-mobile{
            display: inline;
        }
        .hidden-mobile {
          display: none;
        }
        #submissionBtn{z-index: 3 !important; position: fixed !important; bottom: 0px; width: 100vw !important; margin-bottom: 10px; margin-left: 10px; max-width: calc(100vw - 20px);}
        .customDialog.v-dialog--fullscreen {
            width: 100%;
            right: 0px;
            left: 0px;
            border-radius: 0px;
        }
        #map{ z-index: 0 !important; position: fixed !important; width: 100vw !important; height: 100vh !important; border-width: unset !important; border-style: unset !important; border-color: unset !important; overflow-y: hidden !important;}
        #alert{z-index: 3 !important; position: fixed !important; bottom: 30px; width: 100vw !important; max-width: calc(100vw - 20px); margin-top:unset; margin-right: 10px; margin-left: 10px; height: unset;}
        .noScroll{
            position: fixed;
        }

      }
@media (min-width: 767px) {
    .visible-mobile{
        display: none;
    }
    
}

</style>